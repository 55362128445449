import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';
import { AFKCharacter } from '../../../modules/afk/common/components/afk-character';

const AFKGuidesShops: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shop (Emporium) guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_shops.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Shop (Emporium) guide</h1>
          <h2>What to prioritize from every shop available in AFK Journey.</h2>
          <p>
            Last updated: <strong>13/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <StaticImage
          src="../../../images/afk/generic/beginner_shops.webp"
          alt="Guides"
        />
        <p>
          There's a big variety of shops available in AFK Journey where you can
          purchase <AFKItem name="Invite Letter" /> and other summoning
          currency, <AFKItem name="S-Level" /> and <AFKItem name="A-Level" />{' '}
          character dupes, Acorns and much more.
        </p>
        <p>
          To access the shops, you have to visit the Mystical House and then the
          Emporium. Currently there are 5 available shops:
        </p>
        <ul>
          <li>
            <strong>Recruitment Store</strong> - contains summoning currency for
            Banners and Stargaze, Acorns and Soulstones. Refreshes monthly,
          </li>
          <li>
            <strong>Guild Store</strong> - contains summoning currency for
            Banners and Stargaze, Acorns. You can also buy{' '}
            <AFKItem name="Celestial" /> and <AFKItem name="Hypogean" /> dupes
            from here. Refreshes daily and monthly,
          </li>
          <li>
            <strong>Arena Store</strong> - contains <AFKItem name="S-Level" />{' '}
            character dupes. Refreshes monthly,
          </li>
          <li>
            <strong>Dream Store</strong> - contains <AFKItem name="A-Level" />{' '}
            character dupes. Refreshes monthly,
          </li>
          <li>
            <strong>Friendship Store</strong> - contains Affinity items and
            Gestures. Refreshes daily.
          </li>
        </ul>
        <p>
          To learn what to prioritize in each of those shops, check the sections
          below.
        </p>
        <SectionHeader title="Recruitment Store" />
        <StaticImage
          src="../../../images/afk/generic/shop_1.webp"
          alt="Guides"
        />
        <p>
          Focus on discounted <AFKItem name="Epic Invite Letter" /> and{' '}
          <AFKItem name="Stellar Crystal" /> first and only then buy their
          non-discounted version. As a F2P player you won't be able to buy
          anything else, but this depends on how many pulls you will do every
          month -{' '}
          <strong>
            {' '}
            as the currency required to buy anything here comes from summoning
          </strong>{' '}
          .
        </p>
        <p>
          <AFKItem name="Stellar Crystal" /> are required to do pulls on the
          Stargaze banner as you can't use your Diamonds directly there. So the
          amount of Stargazing you can do every month is limited.
        </p>
        <p>
          <strong className="red">Important!</strong>{' '}
          <AFKItem name="Stellar Crystal" /> won't appear in the shop until you
          unlock Stargazing which will require you do to 400 pulls on the other
          banners.
        </p>
        <SectionHeader title="Guild Store" />
        <StaticImage
          src="../../../images/afk/generic/shop_2.webp"
          alt="Guides"
        />
        <p>
          Always make sure to buy the discounted{' '}
          <AFKItem name="Invite Letter" /> every day as it only costs you 210
          Diamonds (down from 300 originally). As for the monthly part of the
          shop, get <AFKItem name="Epic Invite Letter" /> and{' '}
          <AFKItem name="Stellar Crystal" /> every month.
        </p>
        <p>
          <AFKItem name="Stellar Crystal" /> are required to do pulls on the
          Stargaze banner as you can't use your Diamonds directly there. So the
          amount of Stargazing you can do every month is limited.
        </p>
        <p>
          <strong className="red">Important!</strong>{' '}
          <AFKItem name="Stellar Crystal" /> won't appear in the shop until you
          unlock Stargazing which will require you do to 400 pulls on the other
          banners.
        </p>
        <p>
          As for the <AFKItem name="Celestial" /> and{' '}
          <AFKItem name="Hypogean" /> dupes you can buy here, go for{' '}
          <AFKCharacter mode="inline" slug="reinier" enablePopover /> first
          until you reach Mythic+ and unlock his EX weapon. The other options
          have more niche uses and only become good later one, while Reinier is
          already decent with a single copy.
        </p>
        <SectionHeader title="Arena Store" />
        <StaticImage
          src="../../../images/afk/generic/shop_3.webp"
          alt="Guides"
        />
        <p>
          The Arena Store allows you to buy dupes of many{' '}
          <AFKItem name="S-Level" /> characters dupes.
        </p>
        <p>
          We marked the ones you should focus on first with numbers, but{' '}
          <AFKCharacter mode="inline" slug="rowan" enablePopover /> and{' '}
          <AFKCharacter mode="inline" slug="brutus" enablePopover /> take a
          priority if you don't have them yet. Both are one-copy wonders who are
          able to fulfill their role in the early/mid game with a single copy,
          but eventually you will want to upgrade{' '}
          <AFKCharacter mode="inline" slug="rowan" enablePopover /> to Mythic+.
        </p>
        <p>
          As for the other characters, get them to Mythic+ to unlock their EX
          weapons and then move to the next character. Here's some info about
          them:
        </p>
        <ul className="margin-bigger">
          <li>
            <AFKCharacter mode="inline" slug="cecia" enablePopover /> is a great
            damage dealer and you will obtain a copy of her from your first
            10-pull. She makes progression much easier and can be used in
            various areas of the game, but she's not the best anywhere anymore
            (beside story and Battle Drills),
          </li>
          <li>
            <AFKCharacter mode="inline" slug="hewynn" enablePopover /> is a
            great Healer who compared to Rowan and Smokey, can affect the whole
            battlefield at the same time. Her only issue is that she's a bit
            slow and it takes her some time to use her Ultimate, so you often
            use her together with another Healer early on,
          </li>
          <li>
            <AFKCharacter mode="inline" slug="granny-dahnie" enablePopover /> is
            a great tank who is only inferior to Thoran, but in some teams she
            actually outperforms the undead menace. On top of being a great
            tank, she can also debuff and CC enemies and heal herself, allowing
            her to outlast the enemy team,
          </li>
          <li>
            <AFKCharacter mode="inline" slug="bryon" enablePopover /> is a magic
            Ranger who can summon a Falcon and who focuses on debuffing enemies
            while killing them quickly. Once you unlock his EX weapon he also
            gains the ability to revive once per battle,
          </li>
          <li>
            <AFKCharacter mode="inline" slug="cassadee" enablePopover /> is an
            AoE focused mage who also can buff her allies. She shines in Magical
            teams as she also can reduce the enemy's MDEF, which makes her
            pretty good in Dream Realm.
          </li>
        </ul>
        <SectionHeader title="Dream Store" />
        <StaticImage
          src="../../../images/afk/generic/shop_4.webp"
          alt="Guides"
        />
        <p>
          The Dream Store allows you to buy dupes of many{' '}
          <AFKItem name="A-Level" /> characters dupes.
        </p>
        <p>
          For this store, the choice is a bit more complicated as you have
          different paths based on the direction you want to take your account
          to and what do you want to focus on.
        </p>
        <p>
          To learn more about the characters, check our tier list and their
          profiles for their reviews - this should help you with making your
          decision. Still,{' '}
          <strong>
            Dream Realm characters should be your priority, because the only way
            to obtain the currency used in the store is via Dream Realm itself
          </strong>{' '}
          - and if you perform better there, you will gain more currency.
        </p>
        <SectionHeader title="Friendship Store" />
        <StaticImage
          src="../../../images/afk/generic/shop_5.webp"
          alt="Guides"
        />
        <p>
          Friendship Store is pretty straightforward. The Gestures you can buy
          only once and the Affinity items change daily. You can get{' '}
          <AFKItem name="Invite Letter" /> and <AFKItem name="Diamonds" /> by
          raising the affinity of your characters, so it's worth getting the{' '}
          <strong>discounted item</strong> whenever you can. Make sure your
          friend list is full!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesShops;

export const Head: React.FC = () => (
  <Seo
    title="Shop (Emporium) guide | AFK Journey | Prydwen Institute"
    description="What to prioritize from every shop available in AFK Journey."
    game="afk"
  />
);
